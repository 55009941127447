import React from "react"
import './footer-a6.css'
import layoutStyles from "./layout-a6.module.css"

export default  function Footer (props){
  const orslist=props.orgs.map((org,index)=><div key={index} className="org"><span className="org-type">{org.type}</span>{org.desc}</div>)
  const  color=props.color
  return (
    <div className="footer-container">
      <div className="footer-top"></div>
      <div className="footer" style={{background:color}}>
        <div className="supporters" style={{paddingTop:'0'}}>
          <div className="orgs">
            {orslist}
          </div>
          <div className="race-org">
            <div className="name">名称: {props.raceorg.name}</div>
            <div className="address">地址:{props.raceorg.address}</div>
            <div className="phone">客服电话:{props.raceorg.phone} </div>
            <div className="wx">官网微信: {props.raceorg.wx}</div>
          </div>
        </div>
        <div className="line"></div>
        <div className="bottom">
          <div className="record-no">{props.recordNo}</div>
          <div>簇格软件 提供技术支持</div>
        </div>
      </div>

    </div>

  )
}