import React from "react"
import achievementStyles from './a6_achievement.module.css'
import LayoutA6 from "../../components/layout-a6"

export default class A6Achievement extends React.Component {
  render() {
    const color="#1a55a3"
    return(
      <LayoutA6 >
        <div className={achievementStyles.achievement}>
          <div className={achievementStyles.code}>
            <div className={achievementStyles.scanCodeTitle} style={{color:color}}>扫码查看</div>
            <img alt="hello" src={require("../../images/a2/race_code.png")}></img>
            <div className={achievementStyles.scanCodeDesc}>打开微信扫一扫</div>
          </div>

          <div  className={achievementStyles.toWx}>打开微信查看成绩</div>
          <div className={achievementStyles.rankingContainer}>
            {/*<div className="ranking-icon"><img  alt="hello" src={require(this.state.width<480?'../images/ranking-phone.png':"../images/rankings.png")}/></div>*/}
            <div className={achievementStyles.achievementRanking}>
              {/*{ranking}*/}
              <img alt="hello" src={require('../../images/a2/ranking.png')}/>
            </div>
          </div>
        </div>
      </LayoutA6>
    )
  }
}